import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';
import { scrollbar } from 'style/global.styles';

export const root = css`
  @media ${breakpoints.medium} {
    max-height: ${spacing(925)};
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: ${spacing(-16)};
    padding: 0 ${spacing(16)} ${spacing(12)} 0;
    ${scrollbar};
  }

  @media ${breakpoints.large} {
    margin-right: ${spacing(-32)};
    padding-right: ${spacing(32)};
  }
`;

export const item = css`
  padding: ${spacing(16)} 0;
  border-radius: ${spacing(3)};
  margin-bottom: ${spacing(12)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s;
  position: relative;
  color: hsl(var(--color-grayscale-1));

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: hsl(var(--color-grayscale-9));
  }

  @media ${breakpoints.medium} {
    padding: ${spacing(16)};
  }
`;

export const itemActive = css`
  ${item};
  background: hsl(var(--color-grayscale-9));
`;

export const flex = css`
  display: flex;
  align-items: center;
`;

export const itemAvatar = css`
  flex-shrink: 0;
  margin-right: ${spacing(10)};
  width: calc(var(--unit) * 8);
  height: calc(var(--unit) * 8);

  div {
    span {
      display: flex;
      ${font.size.tiny};
      color: hsl(var(--color-white-11));
    }
  }
`;

export const itemNoAvatar = css`
  ${itemAvatar};
  background: hsl(var(--color-error-7));
`;

export const itemOpening = css`
  ${font.size.small};
  ${font.weight.bold};
`;

export const itemCompany = css`
  ${font.size.tiny};
  margin-bottom: ${spacing(4)};

  > span {
    color: hsl(var(--color-grayscale-6));
    margin-left: ${spacing(8)};
  }
`;

export const loadMoreBtn = css`
  margin-top: ${spacing(40)};

  button {
    color: hsl(var(--color-grayscale-1));
    border-color: hsl(var(--color-borderBlack-2));

    path {
      stroke: hsl(var(--color-robin-2));
    }

    &:hover,
    &:focus,
    &:active {
      border-color: hsl(var(--color-borderDark-5));
    }
  }
`;

export const dot = css`
  min-width: ${spacing(6)};
  min-height: ${spacing(6)};
  height: ${spacing(6)};
  width: ${spacing(6)};
  border-radius: 50%;
  background: hsl(var(--color-warning-6));
  margin-left: ${spacing(8)};
`;

export const actions = css`
  position: absolute;
  bottom: ${spacing(8)};
  right: ${spacing(12)};

  svg {
    height: ${spacing(14)};
    width: ${spacing(14)};
  }
`;

export const icon = css`
  &:hover {
    path {
      fill: hsl(var(--color-grayscale-1));
    }
  }
`;

export const options = css`
  transform: rotate3d(0, 0, 1, 90deg);
`;
